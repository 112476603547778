<template>
  <div>
    <heads></heads>
    <div
      class="cen"
      :style="{
        backgroundImage: 'url(' + backgroud + ')',
      }"
    >
      <div class="cen_box">
        <div class="iframeTop"></div>
        <iframe
          id="iframe"
          frameborder="0"
          :src="ebook.ebook_url"
          class="iframe"
          width="100%"
        ></iframe>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getBook } from "@api/public";
export default {
  components: {
    heads,
    foot,
  },
  props: {},
  data() {
    return {
      ebook: {},
      queryWhere: {},
      backgroud: "",
      iframeSrc:
        "https://cloudedu-img.chn5000.com.cn/ebooks/dangzheng/scx/index.html",
    };
  },
  watch: {},
  mounted: function () {
    let that = this;
    getBook(that.queryWhere).then(function (res) {
      that.$set(that, "ebook", res.data);
    });
  },
  created: function () {
    this.queryWhere = this.$route.query;
    document.title = this.$route.meta.title;
    this.backgroud = this.$route.meta.background;
  },
  methods: {},
};

$(document).ready(function () {
  $(document).bind("contextmenu", function (e) {
    return false;
  });
  $(document).bind("selectstart", function () {
    return false;
  });
  $(document).keydown(function (e) {
    return key(arguments[0]);
  });
  function key(e) {
    var e = window.event || e,
      keynum = window.event ? e.keyCode : e.which;
    if ((e.ctrlKey && e.keyCode == 82) || keynum == 116) {
      return false;
    }
    if (keynum == 123) {
      return false;
    }
    if ((e.altKey && keynum == 37) || (e.altKey && keynum == 39)) {
      return false;
    }
    if (e.altKey && keynum == 115) {
      return false;
    }

    if (
      e.keyCode == 83 &&
      (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
    ) {
      e.preventDefault();
    }
  }
});
</script>
<style scoped>
.cen {
  width: 100%;
  min-width: 1484px;
  z-index: -1;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.cen_box {
  width: 100%;
  height: 900px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  /* border: 1px solid blue; */
}

.iframe {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: hidden;
}

.iframeTop {
  width: 100%;
  height: 900px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  background: #fff;
  z-index: 1;
  display: none;
}
</style>